import Vue from "vue";

export default new (class SegmentService {

  async Search(query) {
    const path = "/api/cims/Search";
    let result = null;
    await Vue.prototype.$axios
      .get(path, {
        params: {
          query: query,
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async GetCustomer(organizationNumber) {
    const path = `/api/cims/Customer/${organizationNumber}`;
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async GetCards(organizationNumber) {
    const path = `/api/cims/Cards/${organizationNumber}`;
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async GetProductMainGroups() {
    const path = `/api/cims/ProductMainGroups`;
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }
  async GetTransactions(organizationNumber, cardnumberId, from, to, volumeMin, volumeMax, productMainGroupId ) {
    const path = `/api/cims/Transactions/${organizationNumber}`;
    let result = null;
    await Vue.prototype.$axios
      .get(path, {
        params: {
          cardnumberId: cardnumberId,
          from: from,
          to: to,
          volumeMin: volumeMin,
          volumeMax: volumeMax,
          productMainGroupId: productMainGroupId
        },
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async MonthlyVolumeStatistics(months) {
    const path = `/api/cims/MonthlyVolumeStatistics`;
    let result = null;
    await Vue.prototype.$axios
      .get(path, {
        params: { months: months }
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async QuarterlyVolumeStatistics(fromDate, toDate) {
    const path = `/api/cims/QuarterlyVolumeStatistics`;
    let result = null;
    await Vue.prototype.$axios
      .get(path, {
        params: { fromDate: fromDate, toDate: toDate }
      })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }  

  async DownloadMonthlyVolumeStatistics(){
    const path = `/api/cims/DownloadMonthlyVolumeStatistics`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      responseType: "blob"
    })
      .then((response) => {
        result = response.data;
        const url_1 = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement("a");
        link.href = url_1;
        var reportName = "DownloadMikaelsRapport";
        link.setAttribute("download", `${reportName}.xlsx`);
        // link.setAttribute("download", `${reportName}_${dateStart}--${dateEnd}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }      


})();
