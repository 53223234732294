<template>
    <v-sheet>
      <v-container fill-height class="py-5">
        <v-row class="py-4">
          <div class="flex align-center">
            <v-btn-toggle v-model="monthsSelected" multiple dense>
              <v-btn small v-for="month in months" :key="month.monthno">{{ month.name }}
              </v-btn>
            </v-btn-toggle>



            <v-btn class="ml-3" :disabled="monthsSelected.length == 0" color="primary" small @click="refresh" elevation="2" :loading="loading">Refresh</v-btn>

          </div>
        </v-row>
        <v-row v-if="!monthlyreport">
          <v-col cols="6">
          <v-alert
          border="left"
          colored-border
          color="primary accent-4"
          elevation="2"
        >
          <div v-if="loading">
            <v-progress-linear
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>
          </div>
          <div v-else>
            Pick at least one month and click refresh
          </div>
        </v-alert>
      </v-col>
        </v-row>
        <v-row class="justify-start" v-else>
          <v-card v-for="(m, i) in monthlyreport" :key="`monhtlyreport-${i}`" class="mx-4 my-2" elevation="1" rounded>
            <v-col cols="12">
              <span class="text-h6"> {{ m.title }} </span>
            </v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr class="font-weight-bold">
                    <th class="text-left">Segment</th>
                    <th class="text-left">Category</th>
                    <!-- <th class="text-left">{{ m.period0}}</th> -->
                    <th class="text-left">{{ m.period0 }} m3 </th>
                    <!-- <th class="text-left">{{ m.period1 }}</th> -->
                    <th class="text-left">{{ m.period1 }} m3</th>
                    <th class="text-left">Diff</th>
                    <th class="text-left">Index</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in m.rows" :key="item.title"
                    :class="item.isPartialSum ? 'font-weight-bold yellow darken-2' : ''">
                    <td>{{ item.segment }}</td>
                    <td>{{ item.category }}</td>
                    <!-- <td>{{ item.period0 }}</td> -->
                    <td>{{ item.period0_Quantity_Display }}</td>
                    <!-- <td>{{ item.period1 }}</td> -->
                    <td>{{ item.period1_Quantity_Display }}</td>
                    <td>{{ item.diff_Display }}</td>
                    <td>{{ item.index_Display }}</td>
                  </tr>
                  <tr class="font-weight-bold green darken-4 white--text">
                    <td>Total</td>
                    <td></td>
                    <td>{{ m.period0_Quantity_Display }}</td>
                    <td>{{ m.period1_Quantity_Display }}</td>
                    <td>{{ m.diff_Display }}</td>
                    <td>{{ m.index_Display }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

        </v-row>
      </v-container>
    </v-sheet>
</template>

<script>
import CimsService from "../../../services/CimsService.js";




export default {
  data() {
    return {
      monthsSelected: [],
      months: [
        {
          name: "Jan", month: 1
        },
        {
          name: "Feb", month: 2
        },
        {
          name: "Mar", month: 3
        },
        {
          name: "Apr", month: 4
        },
        {
          name: "May", month: 5
        },
        {
          name: "Jun", month: 6
        },
        {
          name: "Jul", month: 7
        },
        {
          name: "Aug", month: 8
        },
        {
          name: "Sep", month: 9
        },
        {
          name: "Oct", month: 10
        },
        {
          name: "Nov", month: 11
        },
        {
          name: "Dec", month: 12
        },

      ],
      loading: false,
      monthlyreport: null,

    }
  },
  mounted: async () => {
    //await this.refresh();
  },
  computed: {

    isLoaded() {
      return !(!this.monthlyreport  && this.monthsSelected.length == 0)
    },
  },
  methods: {
    async refresh() {
      this.monthlyreport = null;
      this.loading = true;
      let monthspayload = [];
      this.monthsSelected.forEach((i) => {
        let x = this.months[i]
        monthspayload.push(x.month)
      })

      this.monthlyreport = await CimsService.MonthlyVolumeStatistics(monthspayload);
      this.loading = false;
    },
    async download() {
      this.loading = true;
      await CimsService.DownloadMonthlyVolumeStatistics();
      this.loading = false;
    },

  }
};
</script>