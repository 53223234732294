<template>
  <div>
    <v-sheet color="grey lighten-3" >
      <v-container fill-height>
        <v-row dense justify="start">
          <div class="transition-swing text-h5 mt-6 mb-2">Sales Executive Dashboard</div>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet>
      <v-container fluid fill-height>
        <v-row>
          <div class="d-flex flex-row">
            <v-navigation-drawer class="transition-swing  mb-3"  style="min-height:50vh;max-width:200px">
              <v-list-item @click="setActiveComponent('Sales by month', 'MonthlySales')">
                <v-list-item-content>
                  <v-list-item-title>Monthly index</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="setActiveComponent('Sales by quarter', 'QuarterlySales')">
                <v-list-item-content>
                  <v-list-item-title>Quarterly index</v-list-item-title>
                </v-list-item-content>
              </v-list-item>              
            </v-navigation-drawer>
        
        
            <div v-if="report.component != ''" class="ml-2" style="width:100%">
              <div class="ma-2 text-h6">
              {{report.title}}
            </div>
              <component :is="report.component"></component>
            </div>
          </div>

         
        </v-row>
      </v-container>

    </v-sheet>
  </div>
</template>

<script>

import MonthlySales from '../components/reports/executive/MonthlySales.vue'
import QuarterlySales from '../components/reports/executive/QuarterlySales.vue'


export default {
  components: {
    MonthlySales,
    QuarterlySales
  },
  data() {
    return {
      report: { title: "", component: ""},
      loading: false,
    }
  },
  mounted: async () => {
  },
  computed: {
  },
  methods: {
    setActiveComponent(title, component) {
      this.report.title = title;
      this.report.component = component;
    }
  }
};
</script>